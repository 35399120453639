.tci-date {
    width: 100%;
    border-right: 1px solid $border-color;
    .react-datepicker {
        width: 100%;
        border: none;

        &__month-container {
            width: 100%;
        }

        &__current-month {
            font-size: 1.4rem;
            color: var(--color-font-gray);
        }

        &__header {
            background-color: transparent;
            border-bottom: none;
        }

        &__month {
            width: 100%;
            margin: 0;
        }

        &__week,
        &__day-names {
            width: 100%;
            display: flex;
        }

        &__day,
        &__day-name {
            flex: 0 0 14.28%;
            margin: 0;
            font-size: 1.3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        &__day--in-range,
        &__day--range-start {
            border-radius: 0;
        }

        &__day--in-range {
            background-color: var(--color-background-gray-darker);
        }

        &__day--range-start {
            background-color: var(--color-green);
        }

        &__day-name {
            padding-top: 1rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--color-font-gray);
            text-decoration: underline dotted var(--color-font-gray);
        }

        &__day {
            &:hover {
                border-radius: 0;
            }
        }

        &__day--selected {
            background-color: var(--color-green);
            border-color: var(--color-green);
            outline: none;
            border-radius: 0;
            &:hover {
                background-color: var(--color-green);
                border-radius: 0;
            }
        }
    }

    &__span-date {
        margin: 1rem 3rem;
        padding: .3rem;
        font-size: 1.3rem;
        display: inline-block;
        border: 1px solid var(--color-font-gray);
        width: calc(100% - 6rem);
        text-align: center;
    }

    &.first {
        .react-datepicker {
            &__day--selected ~ .react-datepicker__day {
                background-color: var(--color-background-gray-darker);
                color: var(--color-white);
            }

            &__week--selected ~ .react-datepicker__week {
                background-color: var(--color-background-gray-darker);

                .react-datepicker__day {
                    color: var(--color-white);
                }
            }
        }
    }

    &.second {
        .react-datepicker {
            &__day {
                background-color: var(--color-background-gray-darker);
                color: var(--color-white);
            }
            &__day--selected ~ .react-datepicker__day {
                background-color: var(--color-white);
                color: var(--color-black);
            }
            &__day--selected {
                background-color: var(--color-green);
            }

            &__week--selected ~ .react-datepicker__week {
                .react-datepicker__day {
                    background-color: var(--color-white);
                    color: var(--color-black);
                }
            }
        }
    }
}
