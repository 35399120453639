.generic-detail {
    margin-top: 2rem;

    &__header-row,
    &__row {
        display: flex;
        & > * {
            margin-right: 0.5rem;
        }
        input {
            width: 95%;
        }
    }
    &__header-row {
        padding: 2rem 0 1rem 1rem;
        margin-bottom: 1rem;
        background-color: var(--color-background);
        border-bottom: 2px solid var(--color-background-gray-darker);
        border-top: 2px solid var(--color-background-gray-darker);
    }
    &__row {
        line-height: 1.5rem;
        padding: 1rem 0 1rem 1rem;
        &:not(.borderless) {
            border-bottom: 1px solid var(--color-background-gray-darker);
        }
    }
    &__header-th {
        box-sizing: border-box;
        text-transform: uppercase;
        font-weight: 700;
        color: var(--color-font-gray);
        display: flex;
        align-items: flex-end;
        padding-left: 0.5rem;
    }
    &__header-th,
    &__td {
        &--name {
            flex: 40%;
        }
        &--quantity {
            flex: 10%;
        }
        &--price {
            flex: 12%;
        }
        &--tax {
            flex: 12%;
        }
        &--total {
            flex: 18%;
        }
        &--buttons {
            flex: 8%;
        }
        &.w-select {
            @include tci-select-generic;
        }
    }

    &__td {
        &--buttons {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }
        display: flex;
        align-items: center;
        &--text {
            color: var(--color-font);
            text-transform: uppercase;
            font-size: 1.1rem;
            font-weight: 700;
            font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
    }

    &__button {
        padding: 4px 4px 0 4px;
        border: 2px solid var(--border-color);
        border-radius: 3px;
        cursor: pointer;
    }

    &__exchange-rate{
        text-align: center;
    }
}
