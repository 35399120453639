.btn {
    border: none;
    cursor: pointer;
    background: linear-gradient(to bottom, var(--button-first), var(--button-second));
    padding: 0.5rem 2rem;
    min-width: 8rem;
    color: var(--button-font-main);
    position: relative;
    outline: none;
    z-index: 0;
    border: 2px solid var(--button-second);
    &::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.4s;
        background: linear-gradient(to bottom, var(--button-first), var(--button-second));
      
    }
    &.btn-link{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        &:hover{
            color: var(--color-font-gray);
        }
    }
    &.btn-white{
        background: linear-gradient(to bottom, var(--color-white), var(--color-background-gray));
        border: 2px solid var(--border-color);
        color: var(--color-left-background);
        &::after{
            background: linear-gradient(to bottom, var(--color-white), var(--color-background-gray));
        }
    }
}

.btn:hover::after {
    transform: scaleX(1.3) scaleY(1.2);
    opacity: 0;
}

.btn-empty{
    border: 2px solid var(--border-color);
    cursor: pointer;
    background-color: var(--color-white);
    padding: 3px 1rem;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--color-font-gray);
    text-transform: uppercase;
    &>*{
        &:last-child{
            margin-left: 1rem;
        }
    }
}