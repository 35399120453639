.tci-due-easy-pick {
    &__input {
        display: flex;
        border: 2px solid $border-color;
    }
    &__period {
        flex: calc(100% / 7);
        display: flex;
        justify-content: center;
        color: var(--color-font-gray);
        font-weight: 500;
        cursor: pointer;
        &:not(:last-child) {
            border-right: 2px solid $border-color;
         
        }
        &.active {
            font-weight: 700;
            transform: scale(1.05);
            transition: all .3s ease-in-out;
            color: var(--color-font);
        }
    }
}
