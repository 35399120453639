.account-container {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    min-width: 60rem;
    max-width: 100rem;
}

.create-account {
    width: 40%;
    min-width: 33rem;
    max-width: 65rem;
    padding: 2rem;
    background-color: var(--color-white);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    &__row {
        display: flex;
        padding: 1rem 0;
        font-size: 1.2rem;
        &.buttons {
            justify-content: flex-end;
            & > * {
                &:not(:first-child) {
                    margin-left: 1.5rem;
                }
            }
        }
    }
    &__label {
        flex: 1;
        display: flex;
        align-items: center;
    }
    &__input {
        flex: 3;
    }
}
