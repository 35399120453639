.activity-summary {
    width: 65%;
    min-width: 30rem;
    max-width: 100rem;
    align-self: flex-start;
    margin: 5rem auto 0 auto;
    &__row {
        padding: 2rem;
        border-bottom: 1px solid $border-color;
        & > * {
            display: inline-block;
            width: 25%;
            font-size: 1.3rem;
            text-transform: uppercase;
        }
    }

    &__header {
        width: 100%;
        font-size: 1.7rem;
        color: var(--color-font);
        text-align: center;
    }

    &__balance-original,
    &__balance-usd {
        text-align: right;
        font-weight: 700;
    }
    &__balance-tl {
        text-align: right;
        font-weight: 700;
    }
    &__row-total {
        background-color:  var(--color-background-gray-darker);
    }
}
