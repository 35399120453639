.generic-chart {
    &__pie {
        position: relative;
    }
    &__text-container {
        @include absCenter;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 12rem;
    }
    &__value {
        font-size: 1.6rem;
        font-weight: 700;
        &--formatted {
            display: flex;
            flex-wrap: nowrap;
        }
    }
    &__text {
        margin-top: 0.5rem;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 700;
        color: var(--color-font-gray);
    }
    &__header {
        text-align: center;
        font-size: 1.8rem;
    }
}

.tci-bar-container {
    width: 20rem;
    height: 25rem;
    .recharts-surface{
        cursor: pointer;
    }
}

.tci-bar-simple {
    width: 100%;
    height: 90%;
}


.tci-bar {

    &__header {
        text-align: center;
        color: var(--color-font-gray);
        width: 50%;
        margin: auto;
        min-height: 3rem;
    }
    &__amount,
    &__count {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 500;
        color: var(--color-font-gray);
        margin-top: .5rem;
    }
    &__count{
        font-size: 1.2rem;
    }
}

.recharts-surface{
    overflow: visible;
    cursor: pointer;
}