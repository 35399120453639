.activity-form-container,
.exchange-form-container {
    position: fixed;
    z-index: 1;
    width: 85%;
    right: 0;
    height: 100%;
    backdrop-filter: blur(3px);
}
.activity-form,
.exchange-form {
    box-shadow: 0 1rem 2rem rgba(#000000, 0.5);
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    width: 60%;
    min-width: 45rem;
    max-width: 70rem;
    padding: 3rem;
    background-color: var(--color-white);
    &__row {
        @include tci-select-generic;
        display: flex;
        justify-content: flex-start;
        padding: 1rem;
        &.button-row {
            justify-content: flex-end;
            & > * {
                margin-left: 2rem;
            }
        }
        &.close-row {
            justify-content: flex-end;
            padding: 0;
        }
    }

    &__close {
        font-size: 3rem;
        cursor: pointer;
        margin-left: auto;
    }

    &__label {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        &:nth-child(3) {
            margin-left: 3rem;
        }
    }
    &__input {
        flex: 3;
        &.wide {
            flex: 7;
        }
    }
    &__bank-info {
        display: flex;
        align-items: flex-end;
        font-size: 1.3rem;
        color: var(--color-dark-red);
        text-transform: uppercase;
    }
}

.exchange-form {
    &__row {
        &.sell-buy {
            & > * {
                &:not(:last-child){
                    margin-right: 2rem;
                }
            }
        }
    }
}
