.report-sales {
    width: 95%;
    align-self: flex-start;
    &.border-bottom {
        border-bottom: 1px solid $border-color;
    }
    &__summary {
        display: flex;
        & > * {
            &:not(:last-child) {
                margin-right: 5rem;
            }
        }
        &.big-margin {
            .sales-summary {
                margin-right: 30rem;
            }
            .recharts-wrapper {
                margin-top: 2rem;
            }
        }
    }
    &__invoices {
        .invoices,
        .invoices__header {
            max-width: 200rem;
        }
    }

    &__timeline{
        width: 100%;
        padding-top: 2rem;
        height: 30rem;
        background-color: #fff;
        border-radius: 6px;
        margin-bottom: 3rem;
    }
}

.sales-summary {
    padding: 3rem 1rem 1rem 1rem;
    &__row {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        font-size: 1.5rem;
        font-weight: 300;
        color: var(--button-first);
        & > * {
            display: inline-block;
            width: 12rem;
            &:first-child {
                margin-right: 1rem;
            }
        }
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        color: var(--color-font-gray);
        &--toplam {
            color: var(--color-first);
        }
    }
    &__amount {
        text-align: right;
        font-size: 1.4rem;
    }
}

.legend-color {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.container-button {
    text-align: center;
    font-size: 1.2rem;
    &__text {
        width: 100%;
    }
}
