.payment {
    width: 90%;
    align-self: start;
}

.payment-general {
    width: 90%;
    &__row {
        margin-bottom: 2rem;
        display: flex;
        justify-content: flex-end;
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        & > * {
            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }
}

.generic-detail {
    &__header-th,
    &__td {
        &--payment-description {
            flex: 30;
        }
        &--payment-date {
            flex: 10;
            .tci-simple-date {
                width: 90%;
                max-width: 24rem;
                &__icon {
                    margin-left: -3rem;
                }
                .react-datepicker-popper {
                    width: 80%;
                }
            }
        }
        &--payment-amount {
            flex: 10;
        }
        &--payment-source {
            flex: 20;
        }
        &--payment-buttons {
            flex: 10;
            & > * {
                &:not(:first-child) {
                    margin-left: 1rem;
                }
            }
        }
    }
}

.payment-total {
    margin-top: 2rem;
    padding: 1rem 0;
    display: flex;
    justify-content: flex-end;
    &__row {
        width: 30%;
        padding: 2rem 0;
        display: flex;
        justify-content: flex-end;
        border-top: 2px solid var(--border-color);
        border-bottom: 2px solid var(--border-color);
        font-size: 1.2rem;
        font-weight: 500;
    }
    &__label {
        flex: 1;
        padding: 0.5rem;
      
        text-transform: uppercase;
        &::after {
            content: ":";
        }
    }
    &__value {
        flex: 1;
        padding: 0.5rem;
    }
}
