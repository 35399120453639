.left-menu {
    flex: 0 0 $left-width;
    background-color: var(--color-left-background);
    color: var(--color-background-gray-darker);
    &__navigation {
        margin-top: 10rem;
    }
}

.first-level {
    list-style: none;
    padding: 0;
    &__span {
        color: var(--color-background-gray-darker);
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.4rem;
        margin-right: auto;
        margin-left: 2rem;
    }

    &__item {
        transition: all 0.5s ease-in-out;
        padding: 0 0.5rem;
        &:not(:first-child) {
            margin-top: 2rem;
        }
        &.active {
            .first-level__span,
            .first-level__icon {
                transition: all 0.3s ease-in-out;
                color: var(--background-form);
            }
            .first-level__sub-items {
                max-height: 30rem;
                padding-top: 2rem;
                padding-bottom: 1rem;
                opacity: 1;
            }
        }
    }
    &__display {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__icon {
        font-size: 2rem;
        color: var(--color-background-gray-darker);
    }
    &__sub-items {
        transition: all 0.2s;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }
}

.second-level {
    padding: 0;
   
    &__span {
        color: var(--color-background-gray-darker);
        font-size: 1.2rem;
        font-weight: 500;
      
    }
    &__icon {
        font-size: 1.5rem;
        font-weight: 500;
    }
    &__definition {
        margin-right: auto;
        margin-left: 2.5rem;
        &.active {
            .second-level__span{
                color: var(--button-font-main);
            }
        }
    }
    &__item {
        display: flex;
        align-items: center;
        &:not(:first-child) {
            margin-top: 0.8rem;
        }
    }
}
