.invoice-report{
    min-width: 90%;
    min-height: 80vh;
    &__bars{
        display: flex;
        &>*{
            &:not(:first-child){
                margin-left: -5rem;
            }
        }
    }
    &__invoices{
        margin-top: 10rem;
    }
}