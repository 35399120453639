.bank-activities{
    align-self: flex-start;
    width: 80%;
    &__banks{
        width: 80%;
        margin: auto;
        margin-bottom: 1rem;
        .tci-select{
            &__control{
                background-color: var(--color-background-gray-darker);
            }
            &__indicator{
                color: var(--color-font-gray);
                &:hover{
                    color: var(--button-first);
                }
            }
            &__indicator-separator{
                background-color: var(--color-font-gray);
            }
            &__placeholder{
                font-size: 1.4rem;
            }
            &__option{
                font-size: 1.4rem;
            }
            &__value-container{
                font-size: 1.4rem;
            }
        }
    }
  
}