.dashboard-table {
    &__row {
        display: flex;
        min-width: 25rem;
        padding: 0.5rem 0.2rem;
        border-bottom: 1px solid $border-color;
        &.column-head{
            border-bottom: 1px solid $border-color;
        }
        &:last-child{
            border-bottom: 1px solid $border-color;
        }
    }
    &__column {
        flex: 3;
       
        &--definition{
            font-size: 1.3rem;
            font-style: italic;
        }
        &--dued-usd,
        &--not-dued-usd {
            text-align: center;
            font-size: 1.2rem;
            &.column-head{
                font-size: 1.4rem;
                font-weight: 500;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}
