.current-status{
    align-self: flex-start;
    &__row{
        min-width: 100rem;
        min-height: 20rem;
        padding: 1rem;
        &:not(:first-child){
            margin-top: 3rem;
          
        }
        background-color: #fff;
        display: flex;
        justify-content: center;
        &>*{
            &:not(:last-child){
               margin-right: 5rem;
            }
        }
    }
}