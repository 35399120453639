.filter-simple {
    &__links {
        margin: 2rem;
        padding: 0;
        list-style: none;
    }

    &__links-item {
        padding: 0.6rem 1rem;
        background: linear-gradient(to bottom, #d4d3d1, #c8c6c4) #d2d0cf;
        &:not(:first-child) {
            border-top: 1px solid #dedddc;
        }
        &:not(:last-child) {
            border-bottom: 1px solid #dedddc;
        }
        &:first-child {
            border-radius: 6px 6px 0 0;
        }
        &:last-child {
            border-radius: 0 0 6px 6px;
        }
        &:hover {
            background: linear-gradient(to bottom, #c8c6c4, #d2d0cf);
        }
    }

    &__link {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--color-white);
        &:hover {
            color: var(--color-green);
        }
    }

    &__buttons {
        margin: 2rem;
        display: flex;
        justify-content: space-between;
    }

    &__simple-button {
        padding: 1rem 2rem;
        text-transform: uppercase;
        border: 2px solid $border-color;
        cursor: pointer;
        font-size: 1.1rem;
        font-weight: 500;
        transition: all .5s;
        border-radius: 6px;
        &--cancel {
            background-color: var(--color-whilte);
            color: var(--color-font-gray);
            &:hover {
              /*   transition: all 1s; */
                border-color: var(--color-left-background);
                color: var(--color-font);
            }
        }
        &--apply {
            background-color: var(--color-left-background);
            border-color: var(--color-left-background);
            color: var(--color-white);
            &:hover {
              /*   transition: all 1s; */
                border-color: var(--color-white);
                color: var(--color-font-gray);
            }
        }
    }
}
