html {
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%;
  scroll-behavior: smooth;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: #22c39e;
}
body {
  margin: 0px;
  position: relative;
  min-height: 100vh;
  height: 100%;
  a {
    text-decoration: none;
    color: var(--color-font);
    &:hover,
    &:focus {
      outline: 0;
      color: darken($color-font, 50);
      //text-decoration: underline;
    }
  }
  background-color: var(--color-background);
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--color-font);
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.4rem;
}

input {
  width: 100%;
 
  height: $input-height;
  border: 1px solid var(--border-color);
  color: var(--color-font);
  font-size: 1.1rem;
  transition: all 0.5s ease-out;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.text-input:active,
.text-input:focus,
input,
textarea,
textarea:active,
textarea:focus {
  cursor: text;
  outline: 0;
}

input:focus {
  border-color: #22c39e;
}

button {
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.decimal-textbox{
  text-align: right;
}

*{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.loading-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}