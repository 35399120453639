.list-account {
    width: 70%;
    min-width: 40rem;
    max-width: 75rem;
    min-height: 10rem;
    margin-top: 4rem;
    background-color: var(--background-filter);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    &__row {
        display: flex;
        padding: 0.5rem;
        border-bottom: 1px solid $border-color;
        &:nth-child(2n) {
            background: var(--color-background-gray-darker);
        }
    }

    &__cell,
    &__header-cell {
        flex: 1;
        &--name {
            flex: 3;
        }
    }

    &__header-cell {
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 700;
        padding-left: 0.5rem;
    }

    &__cell {
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
    }
}
