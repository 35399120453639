.meta {
    align-self: flex-start;
    width: 60%;
    &__list {
        font-size: 1.3rem;
        margin-top: 2rem;
    }
    &__list-row {
        padding: 0.5rem 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
        &.double-flex {
            border-bottom: none;
            .meta__meta-name {
                flex: 48%;
            }
            .meta__buttons {
                flex: 18%;
                font-size: 1.3rem;
            }
            .meta__meta-user {
                flex: 30%;
            }
            & > * {
                &:not(:last-child) {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    &__meta-name {
        flex: 40%;
        display: flex;
        align-items: center;
    }
    &__meta-main {
        flex: 40%;
        @include tci-select-generic;
    }
    &__buttons {
        flex: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & > * {
            cursor: pointer;
            margin-right: 2rem;
        }
    }

    &__checkmark {
        flex: 1;
        font-size: 1.6rem;
        color: var(--color-axa);
    }
    &__header{
        margin: 1rem 0 .5rem 0;
    }
}
