.tci-simple-date {
    display: flex;
    align-items: center;
    position: relative;
    &__icon {
        margin-left: -2rem;
        position: relative;
        z-index: 0;
        font-size: 1.3rem;
        color: var(--color-font-gray);
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .react-datepicker {
        width: 100%;
        &__input-container {
            input {
                padding-left: 1rem;
            }
        }
        &__header {
            width: 100%;
        }
        &__month-container {
            width: 100%;
        }
        &__day-name,
        &__day {
            width: 13%;
            margin: .8%;
        }
    }
    .react-datepicker-popper {
        width: 70%;
    }
}
