.modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(#000000, .3);
    z-index: 10000;
    &__content {
      width: 70%;
      max-width: 60rem;
      @include absModalCenter;
      @include respond(tab-port){
          display: table;
          width: 60%;
      }
      background-color: var(--color-white);
      box-shadow: 0 2rem 4rem 0 var(--color-black-shadow);
      padding: 2rem 2rem .5rem 2rem;

    }
  
    &__left {
       text-align: center; 
       font-size: 3rem;
      @include respond(tab-port){
          display: table-cell;
          vertical-align: middle;
      }
     
    }
  
    &__right {
      @include respond(tab-port){
          display: table-cell;
          padding: 0 2rem;
      }
    
      vertical-align: middle;
    }
  
    &__img {
      margin-top: 2rem;
      width: 15%;
      height: auto;
      margin: 1rem auto 0 auto;
      @include respond(tab-port){
          width: 7rem;
      }
      
    }
  
    &__text {
      padding-bottom: 1rem;
      margin: .5rem 0;
      font-size: 1.1rem; 
      text-align: justify;
      @include respond(tab-port){
          font-size: 1.35rem;
      }
    }
  
    &__buttons {
      text-align: right;
      padding-bottom: 1rem;
      &>button{
        &:not(:last-child){
          margin-right: 1rem;
        }
      }
    }
  
    &__close{
      font-size: 3rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }
  