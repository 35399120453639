.basic-form {
    &.login {
        margin-top: -15rem;
    }
    width: 50%;
    max-width: 45rem;
    padding: 5rem;
    background-color: var(--background-form);
    box-shadow: 0 1rem 2rem rgba(#000000, 0.5);
    &__icon {
        width: 2rem;
        height: 2rem;
        margin-right: 2rem;
        &--medium {
            width: 4rem;
            height: 4rem;
        }
        &--big {
            width: 8rem;
            height: 8rem;
        }
        &--huge {
            width: 12rem;
            height: 12rem;
        }
    }
    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
    }
    &__label {
        width: 15%;
        display: flex;
        justify-content: flex-end;
        font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 13px;
        font-weight: 600;
    }
    &__input-container {
        width: 50%;
        min-width: 10rem;
        min-width: 20rem;
        &.button {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__input {
        width: 100%;
    }

    &__button {
        text-transform: uppercase;
    }
}
