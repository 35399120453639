.invoice-total {
    margin-top: 2rem;

    &__row {
        display: flex;
        & > * {
            flex: 5;
        }
    }

    &__value-container {
        flex: 3;
        display: flex;
        padding: 1rem 2rem;
        justify-content: space-between;
        border-top: 1px solid var(--color-background-gray-darker);
    }

    &__value-label,
    &__value {
        font-size: 1.3rem;
        font-weight: 700;
        color: var(--color-font-gray);
    }

    &__value-label {
        text-transform: uppercase;
    }
}
