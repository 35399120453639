:root {
    --color-background: #f2f1f1;
    --color-green: #22c39e;
    --color-light-green: #88d684;
    --color-light-blue: #2ac;
    --color-font-gray: #999593;
    --color-background-gray: #f2f1f1;
    --color-left-background: #373636;
    --color-font: #403637;
    --color-background-gray-darker: #d2d0cf;
    --header-height: 5rem;
    --padding-top: 5rem;
    --padding-footer: 4rem;
    --border-color: #c5c4c2;
    --button-first: #685f5d;
    --button-second: #5b5251;
    --button-font-main: #ffffff;
    --background-form: #ffffff;
    --color-black-shadow: rgba(#000000, 0.5);
    --color-black: #000000;
    --color-black-background: rgba(#000000, 0.3);
    --color-white: #ffffff;
    --color-dark-red: #b20000;
    --background-filter: #fdfdfd;
    --color-coface: #03254c;
    --color-hermes: #3a7ca5;
    --color-atradius: #990f02;
    --color-eximbank: #a71a40;
    --color-axa: #1520a6;
    --color-credendo: #ff6600;
}
$color-font: #403637;
$background-filter: #fdfdfd;
$border-color: #c5c4c2;

$left-width: 15%;
$right-width: 100%-$left-width;
$input-height: 2.5rem;
$bp-maggie: 240px;
$bp-lisa: 31.25em; //500px
$bp-bart: 50em; //800px;
$bp-marge: 75em; //1200px;
$bp-homer: 93.75em; //1500px;

$header-height: 8rem;
