.check-group, .radio-group {
  display: block;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &__input {
    display: none;
  }
  &__label {
    cursor: pointer;
    position: relative;
    padding-left: 2rem;
  }
  &__radio-button {
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border: 1px solid var(--button-second);
    position: absolute; //burasi radio button'u tam yerine koyabilmek için
    left: 0;
    top: 0;
    &::after {
      //burasi içi icin
      content: "\2713";
      display: block;
      font-size: 1.5rem;
      font-weight: 900;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--button-second);
      transition: opacity 0.3s;
      opacity: 0;
    }
  }
  &__text {
    font-size: 1.2rem;
  }
  &__input:checked ~ &__label &__radio-button::after {
    opacity: 1;
  }
}
