.activities {
    overflow-y: scroll;
    width: 100%;
    max-width: 110rem;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 6px;
    padding: 1rem;
    &__header {
        display: flex;
        width: 99%;
        max-width: 110rem;
        padding: 0.5rem 1rem;
        .middle {
            display: flex;
            justify-content: center;
        }
        & > * {
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--color-font-gray);
        }
    }

    &__item {
        padding: 0.5rem 0;
        display: flex;
        align-items: stretch;
        border-bottom: 1px solid $border-color;
        /*  &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        } */
        padding: 0.5rem 0;
        &:hover,
        &:active {
            background-color: var(--background-filter);
        }

        & > * {
            font-size: 1.15rem;
            /*  display: flex;
            align-items: center; */
            &.middle {
                text-align: center;
            }
            &.right {
                text-align: right;
            }
        }
        div[class*="__item-"] {
            cursor: pointer;
        }
    }

    &__header-type,
    &__item-type {
        flex: 16%;
    }
    &__header-activitydate,
    &__item-activitydate {
        flex: 11%;
    }
    &__header-account,
    &__item-account {
        flex: 25%;
    }
    &__header-description,
    &__item-description {
        flex: 25%;
    }
    &__header-amount,
    &__item-amount {
        flex: 9%;
    }
    &__header-balance,
    &__item-balance {
        flex: 9%;
    }
    &__header-button,
    &__item-button {
        flex: 4%;
        .red {
            color: var(--color-dark-red);
        }
    }
    &__item-description,
    &__item-account,
    &__item-type {
        text-transform: uppercase;
    }
}
