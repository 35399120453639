.header{
    display: flex;
    position: fixed;
    width: 85%;
    height: var(--header-height);
    align-items: center;
    justify-content: flex-end;
}

.logout{
    display: flex;
    margin-right: 3rem;
    font-size: 1.5rem;
    align-items: center;
    &__icon{
        padding: 1rem;
        border-radius: 50%;
        margin-left: 1rem;
        color: var(--color-font-gray);
        background-color: var(--color-background-gray-darker);
    }
   
}