.filter-container {
  width: 100%;
  max-width: 110rem;
  margin: auto;
  margin-bottom: 3rem;
  position: relative;
}

.filter-button {
  display: flex;
  justify-content: center;
  &.padded {
    width: 4rem;
    align-items: center;
  }
  .btn-filter {
    border-radius: 6px;
    &::after {
      border-radius: 6px;
    }
  }
  & > * {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }


  .filter-button-invoice {
    display: flex;
    justify-content: center;
    &.padded {
      width: 4rem;
      align-items: center;
    }
    .btn-filter {
      border-radius: 6px;
      &::after {
        border-radius: 6px;
      }
    }
    & > * {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }}

  

  .icon-inner {
    height: 2rem;
    width: 2rem;
    fill: var(--color-light-blue);
    cursor: pointer;
  }
}

.filter {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  &__date-picker {
    transition: all 0.4s;
    background-color: var(--color-white);
    display: flex;
    width: 100%;
    position: absolute;
    z-index: 1;
    box-shadow: 0 1rem 2rem rgba(#000000, 0.5);
    .tci-date {
      flex: 38%;
    }
    .filter-simple {
      flex: 24%;
    }
    overflow: hidden;
    max-height: 0;
    &.active {
      max-height: 40rem;
    }
  }

  &__date-header {
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--color-font-gray);
  }
}

.filter-combo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 1.3rem;
  background-color: var(--color-background-gray-darker);
  border-radius: 6px 0 0 6px;
  margin-right: 2px;
  cursor: pointer;
  & > * {
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  &__text {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 700;
    margin: 0 1rem 0 1rem;
  }
}

.filter-text {
  flex: 1;
  display: flex;
  background-color: var(--color-background-gray-darker);
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-radius: 0 6px 6px 0;
  margin-right: 0.7rem;
  &__icon {
    font-size: 2rem;
    color: var(--button-first);
    cursor: pointer;
  }
  &__text {
    flex: 1;
    font-size: 1.8rem;
    font-weight: 500;

    background-color: transparent;
    border: none;
  }
}

.filter-list {
  margin-top: 2px;
  display: flex;
  max-width: 20rem;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  background: var(--background-filter);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  /* --webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
    --moz-box-shadow: 0 3px 6px rgba(0,0,0,.25) */
  position: absolute;
  &.active {
    max-height: 40rem;
  }
  &__list {
    list-style: none;
    padding: 0;
    min-width: 20rem;
  }
  &__item {
    width: 100%;
    line-height: 3rem;
    padding-left: 1rem;
    &:hover,
    &:focus {
      outline: 0;
      background-color: darken($background-filter, 50);
      .filter-list__item-text {
        color: lighten($background-filter, 50);
      }
      cursor: pointer;
    }
  }
  &__item-text {
    text-transform: uppercase;
    color: var(--color-font-gray);
    font-weight: 800;
    font-size: 1.2rem;
    &:hover,
    &:focus {
      color: lighten($background-filter, 50);
    }
  }
}
