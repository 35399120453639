.selected-filter {
    display: flex;
    align-items: stretch;
    background-color: var(--color-background-gray-darker);
    position: relative;

    &__text {
        color: var(--color-font-gray);
        font-weight: 700;
        text-transform: uppercase;
      
    }
  
    &.active {
        .selected-filter__list-container {
            max-height: 40rem;
            padding: 1rem 0;
            overflow: auto;
        }
    }

    &__list-container {
        max-height: 0;
        min-width: 50rem;
        top: $input-height + 0.25rem;
        position: absolute;

        background: var(--background-filter);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        /*   &:hover,
        &:active {
            max-height: 40rem;
            padding: 1rem 0;
        } */
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        &.datetime {
            height: 21rem;
            overflow-y: scroll;
        }
    }

    &__item {
        padding: 0.5rem 1rem;
        cursor: pointer;
        &.datetime {
            &:hover,
            &:active {
                background-color: var(--color-background-gray-darker);
            }
        }
        &.selected {
            .selected-filter__text {
                color: var(--color-green);
                font-size: 1.1rem;
                overflow: auto;
             
            }
        }

        &.more,
        &.less {
            font-size: 1.2rem;
            line-height: 2rem;
        }

        &.more {
            border-top: 3px solid var(--color-background-gray);
        }
        &.less {
            border-bottom: 3px solid var(--color-background-gray);
        }
    }

    &__combo {
        display: flex;
        align-items: center;

        padding: 0 0.8rem;
        color: var(--color-font);
        font-weight: 700;
        font-size: 1.2rem;
        text-transform: uppercase;
        cursor: pointer;
        overflow: auto;
    }

    &__combo-item-icon {
        margin-left: 0.5rem;
    }

    &__combo-item-times {
        margin-left: 0.5rem;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 5px solid var(--color-background-gray);
        min-height: 3rem;
    }

    &__bottom-button {
        font-size: 1.3rem;
        margin-left: -1rem;
        color: var(--color-font-gray);
        font-weight: 700;
        text-transform: uppercase;
        
    }

    .check-group {
        color: var(--color-font-gray);
        font-weight: 700;
        text-transform: uppercase;
            &__radio-button {
            border-color: var(--color-font-gray);
            &::after {
                color: var(--color-font-gray);
            }
        }
    }
}
