.report-picker {
    &__button {
        padding: 1rem;
        cursor: pointer;
        color: var(--color-font-gray);
        font-size: 1.5rem;
        font-weight: 500;
        display: flex;
        align-items: center;
    }
    .filter__date-picker {
        width: 95%;
    }
    .icon {
        margin-right: 1rem;
        font-size: 1.7rem;
    }
}
