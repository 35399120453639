.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  margin-right: 0;
  &--medium {
    width: 4rem;
    height: 4rem;
  }
  &--big {
    width: 8rem;
    height: 8rem;
  }
  &--huge {
    width: 12rem;
    height: 12rem;
  }
}

.gear {
  -webkit-animation: rotation 2s infinite linear;
  -moz-animation: rotation 2s infinite linear;
  -o-animation: rotation 2s infinite linear;
  animation: rotation 2s infinite linear;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
}

.icon-cross {
  color: var(--color-dark-red);
}
.icon-minus {
  color: var(--color-dark-red);
}
.icon-plus {
  color: var(--color-green);
}
.icon-checkmark {
  color: var(--color-green);
}
