.culture-container {
    margin-right: 2rem;
    a{
        display: inline-block;
        color: transparent;
        -webkit-text-fill-color: transparent;
        padding: .5rem;
        width: 3rem;
        height: 3rem;
    }

    #en-Us{
        background-image: url(../img/en.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
    #tr-Tr{
        background-image: url(../img/tr.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
}