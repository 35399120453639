@mixin clear-fix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absModalCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
}

@mixin respond($breakpoint) {
  @if $breakpoint == tab-port {
    @media (min-width: $bp-lisa) {
      //500px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (min-width: $bp-bart) {
      //800px
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: $bp-marge) {
      //1200px
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: $bp-homer) {
      //1500px
      @content;
    }
  }
}

@mixin tci-select-generic {
  .tci-select-container {
    width: 95%;
  }
  .tci-select__control {
    min-height: 1.5rem;
    border-radius: 0;
    input {
      height: 1.5rem;
    }
  }
  .tci-select__value-container div[class$="-Input"] {
    margin: 0 2px;
    padding: 1px;
    min-width: 2rem;
  }
  .tci-select__input {
    margin: 0;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .tci-select__clear-indicator {
    padding: 0;
  }
  .tci-select__indicator {
    line-height: 1.5rem;
    padding: 0 8px;
    svg {
      height: 1.5rem !important;
    }
  }
}
