.generic-installment {
    display: flex;
    width: 85%;
    flex-direction: column;
    &__checkbox {
        font-weight: 500;
        &--red {
            color: var(--color-dark-red);
        }
        &--green {
            color: var(--color-green);
        }
    }
    &__no {
        flex: 1;
    }
    &__date {
        flex: 2;
    }
    &__amount {
        flex: 2;
    }
    &__buttons {
        flex: 2;
    }
    &__source {
        flex: 3;
    }
    &__rate {
        flex: 1;
    }
    &__button {
        padding: 0.4rem 0.3rem 0.2rem 0.3rem;
        cursor: pointer;
        border: 2px solid var(--border-color);
        border-radius: 3px;
        &:not(:first-child) {
            margin-left: 1rem;
        }
    }
    &__row {
        &:nth-child(2n) {
            background: #fafafa;
        }
        &.installment-header {
            color: var(--color-font-gray);
            background-color: var(--color-background);
            text-transform: uppercase;
            border-bottom: 2px solid var(--color-background-gray-darker);
            border-top: 2px solid var(--color-background-gray-darker);
        }
        display: flex;
        justify-content: space-between;
        line-height: 1rem;
        padding: 0.8rem 0;
        & > * {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .generic-installment__buttons {
            padding-left: 5rem;
            justify-content: flex-start;
        }
        .tci-simple-date {
            width: 95%;
            .react-datepicker-popper {
                width: 80%;
            }
        }
    }
    &--amount {
        width: 90%;
    }
    &__span {
        font-weight: 500;
        font-size: 1.22rem;
    }
    .w-select {
     @include tci-select-generic
    }
}
