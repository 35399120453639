.create-expense {
    width: 98%;
    height: 98%;
    background-color: var(--color-white);
    border-radius: 6px;
    box-shadow: 1px 1px 2px 2px rgba(#000000, .5);
}

.expense-general {
    line-height: 7rem;
    min-height: 80vh;
    &__row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-background-gray);
        &.center{
            justify-content: center;
        }
        &.larger {
            line-height: 9rem;
            .invoice-general__input {
                padding-top: 1rem;
            }
        }
        &.narrow {
            line-height: 5rem;
        }
        &.borderless {
            border-bottom: none;
        }
    }

    &__label {
        padding-left: 2rem;
        flex: 0 0 25%;
        display: flex;
        align-items: center;
        &--icon {
            font-size: 4rem;
            color: var(--color-light-blue);
            flex: 0 30%;
        }
        &--icon-small {
            font-size: 2rem;
            flex: 0 30%;
        }
        &--label {
            flex: 0 70%;
            font-size: 1.4rem;
            font-weight: 700;
            text-transform: uppercase;
            color: var(--color-font-gray);
        }
    }
    &__input {
        flex: 0 0 45%;
        align-self: center;
        line-height: 2rem;
        font-size: 1.3rem;
        input{
            font-size: 1.3rem;
        }
        &--text {
            font-style: italic;
        }
        &--center{
            text-align: center;
        }
        .tci-select {
            &__control {
                min-height: 2rem;
                border-radius: 0;
                input {
                    height: 2rem;
                }
                &--is-focused {
                    border-color: var(--color-light-green);
                    box-shadow: var(--color-light-green);
                    &:hover {
                        border-color: var(--color-light-green);
                    }
                }
            }
            &__single-value{
                text-transform: uppercase;
            }
            &__input {
                margin: 0;
                padding-top: 1px;
                padding-bottom: 1px;
            }
            &__value-container {
                div[class$="-Input"] {
                    margin: 0 2px;
                    padding: 1px;
                }
            }
           
            &__indicator-separator {
                margin: 1px 0;
            }
            &__indicator {
                padding: 2px 4px;
            }
            &__menu {
                border-radius: 0;
            }
            &__option {
                text-transform: uppercase;
            }
        }
        &.double-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            & > * {
                flex: 45%;
                &:not(:last-child) {
                    margin-right: 1rem;
                }
                &.invoice-general__input--text {
                    line-height: 100%;
                }
            }
            margin: 0.5rem 0;
        }
    }
    &__buttons {
        flex: 0 0 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > * {
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
        .check-group__text{
            font-size: 1.5rem;
        }
    }

    &__extra{
        flex: 0 0 25%;
        display: flex;
        justify-content: center;
        font-size: 1.4rem;
    }
}
