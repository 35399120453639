.pagination {
    list-style: none;
    margin: 0;
    display: flex;
    padding: 1rem 0;
    justify-content: center;
    & > * {
        &:not(:first-child) {
            margin-left: 0.5rem;
        }
    }
    &__item {
        font-size: 1.3rem;
        width: 2.5rem;

        &.active {
            background-color: var(--color-green);
        }
        text-align: center;
        &--main {
            background-color: var(--color-left-background);
            color: var(--color-white);
            //border-radius: 6px;
            cursor: pointer;
        }
    }
}
