.expenses {
    overflow-y: scroll;
    width: 95%;
    max-width: 110rem;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 6px;
    .overdued {
        .expenses__item-image {
            color: var(--color-dark-red);
        }
        .expenses__item-due {
            color: var(--color-dark-red);
            .expenses__item-date--date {
                color: var(--color-dark-red);
            }
        }
        .expenses__item-amount {
            color: var(--color-dark-red);
            .expenses__item-amount--main {
                color: var(--color-dark-red);
            }
        }
    }
    .waiting{
        .expenses__item-image {
            color: var(--color-green);
        }
        .expenses__item-due {
            color: var(--color-green);
            .expenses__item-date--date {
                color: var(--color-green);
            }
        }
        .expenses__item-amount {
            color: var(--color-green);
            .expenses__item-amount--main {
                color: var(--color-green);
            }
        }
    }
    &__item {
        display: flex;
        align-items: stretch;
        border-bottom: 1px solid $border-color;
        /*  &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        } */
        padding: 0.5rem 0;
        &:hover,
        &:active {
            background-color: var(--background-filter);
        }
    }
    &__header {
        display: flex;
        width: 95%;
        max-width: 110rem;
        padding: 0.5rem 0;
        .middle {
            display: flex;
            justify-content: center;
        }
        & > * {
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--color-font-gray);
        }
    }
    &__header-image {
        flex: 8%;
    }
    &__header-link {
        color: var(--color-font-gray);
    }
    &__header-definition {
        flex: 45%;
    }
    &__header-date {
        flex: 15%;
    }
    &__header-amount {
        flex: 15%;
    }
    &__header-button,
    &__item-button {
        flex: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        .red {
            color: var(--color-dark-red);
        }
    }
    &__item-image {
        flex: 8%;
        display: flex;
        justify-content: center;
        font-size: 3rem;
        font-weight: lighter;
        align-items: center;
        padding: 1rem 0;
    }
    &__item-definition {
        flex: 45%;
        &--top {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 700;
            color: var(--color-font);
            padding: 0.5rem 0;
        }
        &--bottom {
            font-size: 1.2rem;
            font-weight: 500;
            color: var(--color-font-gray);
            & > * {
                &:not(:first-child) {
                    margin-left: 0.5rem;
                }
            }
        }
        &--expense-type {
            padding: 1px 5px;
            border: 1px solid $border-color;
        }
        &--account-manager,
        &--vendor,
        &--account-policy {
            padding: 1px 5px;
            border: 1px solid $border-color;
        }
    }
    &__item-date {
        flex: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &--date {
            font-size: 1.3rem;
            letter-spacing: 1px;
            font-weight: 500;
            color: var(--color-font);
        }
        &--text {
            font-size: 1.2rem;
        }
    }
    &__item-amount {
        flex: 15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &--main {
            font-size: 1.55rem;
            letter-spacing: 1px;
            font-weight: 500;
            color: var(--color-font);
        }
        &--total {
            font-size: 1.35rem;
        }
    }
    &__item-remaining {
        background-color: red;
        flex: 17%;
    }
}

.invoice-footer {
    height: var(--padding-footer);
    width: 100%;
}
